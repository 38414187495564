import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { SearchBox } from 'react-instantsearch-dom';

const query = graphql`
  {
    file(relativePath: { eq: "hero-recipes.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    file1: file(relativePath: { eq: "hero-recipes-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    file2: file(relativePath: { eq: "hero-recipes-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

const Hero = () => {
  const { file, file1, file2 } = useStaticQuery(query);

  return (
    <div className="flex flex-col">
      <div className="pt-6 md:pt-8">
        <div className="flex flex-wrap items-center pb-12">
          <div className="lg:w-1/3 w-full">
            <div className="ml-2 lg:ml-24">
              <h2 className="title-font sm:text-3xl text-2xl mb-4 font-medium text-gray-900">
                Recetas
              </h2>
              <p className="mb-8 sm:text-xl leading-relaxed">
                Cuidar de la diabetes no significa que tienes que sacrificar el
                disfrutar de la buena comida. DeDiabetes ofrece más de 100
                recetas saludables y especialmente seleccionadas para aquella
                persona con diabetes, incluidos postres, comidas bajas en
                carbohidratos, comidas bajas en azúcar, y opciones vegetarianas.
                Tode esto con el objectivo de ofrecerte ideas de comidas no solo
                saludables sino también sabrosas.
              </p>

              {/* Forma de Envío */}
              <SearchBox className="border w-5/6" />
            </div>
          </div>
          <div className="hidden lg:block lg:w-2/3 w-full">
            <div className="flex justify-around">
              <div className="">
                <GatsbyImage
                  image={file.childImageSharp.gatsbyImageData}
                  alt="hero image"
                  className="rounded-lg"
                />
              </div>
              <div className="flex flex-col justify-between">
                <div>
                  <GatsbyImage
                    image={file1.childImageSharp.gatsbyImageData}
                    alt="hero image"
                    className="rounded-lg"
                  />
                </div>
                <div>
                  <GatsbyImage
                    image={file2.childImageSharp.gatsbyImageData}
                    alt="hero image"
                    className="rounded-lg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

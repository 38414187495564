import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import styled from 'styled-components';
import Top5Recipes from '../components/Top5Recipes';
import Hero from '../components/Recipes/Hero';
import { orderBy } from 'lodash';

import {
  InstantSearch,
  Hits,
  Highlight,
  Stats,
  Pagination,
  RefinementList,
} from 'react-instantsearch-dom';

import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';

//** The good documentation for this is in
// https://github.com/typesense/typesense-instantsearch-adapter

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: 'NH7eqMFZ7dRku6Ij4eT3YThhQYmwxm8l', // Be sure to use the search-only-api-key
    nodes: [
      {
        host: 'search.dediabetes.com',
        port: '',
        protocol: 'https',
      },
    ],
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    queryBy: 'title,description',
    filterBy: 'type: recetas',
    perPage: 8,
    //sortBy: 'name:asc',
  },
});

const searchClient = typesenseInstantsearchAdapter.searchClient;

function Hit(props) {
  const link = {
    title: <Highlight attribute="title" hit={props.hit} />,
    plainTitle: props.hit.title,
    description: <Highlight attribute="description" hit={props.hit} />,
    url: props.hit.page_path,
    image: props.hit.image,
  };
  return <LinkCard link={link} />;
}

const LinkCard = ({ link }) => {
  const description = link.description.props.hit.description.substring(0, 260);

  return (
    <div className="">
      <img
        className="w-100"
        src={link.image}
        alt={link.plainTitle}
        title={link.plainTitle}
      />
      <Link to={link.url}>
        <h2 className="hover:text-themeBrandColor my-4 text-xl leading-none font-headings">
          {link.title}
        </h2>
      </Link>
    </div>
  );
};

const Recipes = () => {
  return (
    <Layout>
      <Wrapper>
        <InstantSearch searchClient={searchClient} indexName="pages_v1">
          <Hero />
          <div className="container mx-auto">
            <div className="ml-2 lg:ml-24 text-themeDark">
              <RefinementList
                attribute="recipe-type"
                limit={20}
                transformItems={items => orderBy(items, 'label', 'asc')}
              />
            </div>
          </div>
          <div className="grid grid-cols-blog w-full container mx-auto gap-x-4 p-2">
            <div className="col-span-2 md:col-span-1 mx-autoleading-losse my-6 xl:px-24">
              <div className="flex gap-4 flex-col-reverse sm:flex-row">
                <div className="left px-2">
                  <Hits hitComponent={Hit} className="list-none" />
                  <Pagination hitsPerPage={3} />
                  <Stats />
                </div>
              </div>
            </div>

            {/* Side Bar */}
            <aside className=" hidden md:block md:visible mx-autoleading-losse bg-white my-6">
              <Top5Recipes />
              {/* <SubscribeSide /> */}
              {/* <Top5Posts /> */}
            </aside>
          </div>
        </InstantSearch>
      </Wrapper>
    </Layout>
  );
};

export default Recipes;

const Wrapper = styled.div`
  h3 {
    margin: 0;
  }
  .ais-Hits-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .ais-Hits-item{ 
    width: 98%;
    @media (min-width: 1280px) {
      width: 48%;
    }
  }
  .ais-RefinementList-list {
    display: flex;
    gap: 0.5rem;
    justify-items: space-around;
    align-items: center;
    flex-wrap: wrap;
    
  }
  .ais-RefinementList-item {
    margin: 0;
    border-radius: 0.9rem;
    border: 1px solid rgba(138, 4, 13, 1);
    padding: 2px 8px;
    transition: all 0.5s;
    :hover,
    &.ais-RefinementList-item--selected {
      background: rgba(138, 4, 13, 1);
      color: white;
    }
  }

  .ais-ToggleRefinement-count,
  .ais-HierarchicalMenu-count,
  .ais-Menu-count,
  .ais-RatingMenu-count,
  .ais-RefinementList-count {
    border: 1px solid rgba(138, 4, 13, 0.3);
    color: rgba(138, 4, 13, 0.3);
  }

  .ais-RefinementList-showMore {
    border: 1px solid rgba(138, 4, 13, 1);
    color: rgba(138, 4, 13, 0.7);
    margin-top: 1rem;
    :focus {
      border-color: rgba(138, 4, 13, 1);
    box-shadow: rgba(138, 4, 13, 1); 0 0 0 1px, rgb(35 38 59 / 5%) 0 2px 0 1px;
    }
  }

  .ais-RefinementList-item--selected,
  .ais-RefinementList-checkbox,
  .ais-GeoSearch-input:checked {
    color: rgba(138, 4, 13, var(--tw-text-opacity));
  }

  .ais-Hits-item {
    padding: 0;
    border: none;
  }
`;
